<template lang='pug'>
  .newsletter
    particles
      //h1.animated.fadeInUp Blog
      //h3.animated.fadeInUp.wait-p2s Latest news and updates from the Requarks team sekuritim
</template>

<script>
import Particles from '../components/Particles'

export default {
  components: {
    Particles
  },
  data () {
    return { }
  }
}
</script>
